export const ENV_ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENV;
export const ENV_IS_PRODUCTION = ENV_ENVIRONMENT === "production";
export const ENV_APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const ENV_BE_BASE_URL = process.env.NEXT_PUBLIC_BE_BASE_URL;
export const ENV_FE_BASE_URL = process.env.NEXT_PUBLIC_FE_BASE_URL;

export const ENV_PASSWORD = process.env.NEXT_PUBLIC_PASSWORD;
export const ENV_NAMESPACE = process.env.NEXT_PUBLIC_NAMESPACE;
export const ENV_WALLETCONNECT_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID;
export const ENV_G_MEASUREMENT_ID = process.env.NEXT_PUBLIC_G_MEASUREMENT_ID;
export const ENV_GATED_ENABLED = process.env.NEXT_PUBLIC_GATED_ENABLED;

export const ENV_FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const ENV_FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const ENV_FIREBASE_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const ENV_FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const ENV_FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const ENV_FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const ENV_FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
export const ENV_FIREBASE_PUBLIC_VAPID_KEY =
  process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY;
