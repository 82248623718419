import { HYDRATE } from "next-redux-wrapper";
import { Admin } from "../../models/entities/Admin";
import { Social } from "../../models/entities/Social";
import { User } from "../../models/entities/User";
import { UserWalletAddressEntity } from "../../models/entities/UserWalltetAddressEntity";
import UserQuest from "../../models/entities/user-quest/UserQuest";
import { Providers } from "../../utils/enums";
import {
  ADD_ALTERNATE_LOGIN_CODE_RESET,
  ADD_ALTERNATE_LOGIN_COMPLETED,
  ADD_EMAIL,
  ADD_EMAIL_CHECK_VERIFICATION_ERROR,
  ADD_EMAIL_COMPLETED,
  ADD_EMAIL_ERROR,
  ADD_WALLET_ADDRESS,
  ADD_WALLET_ADDRESS_COMPLETED,
  ADD_WALLET_ADDRESS_ERROR,
  ADD_WALLET_ADDRESS_REJECT_SIGN,
  ADD_WALLET_ADDRESS_REJECT_SIGN_COMPLETED,
  ADD_WALLET_ADDRESS_REJECT_SIGN_ERROR,
  ADD_WALLET_ADDRESS_VERIFY_SIGN,
  ADD_WALLET_ADDRESS_VERIFY_SIGN_ERROR,
  ADMIN_IS_QUEST_APPROVAL_PENDING,
  AUTH_LOGIN_COMPLETED,
  AUTH_VERIFY_SIGNATURE,
  GET_USER_REFERRAL_CODE,
  GET_USER_REFERRAL_CODE_COMPLETED,
  GET_USER_REFERRAL_CODE_ERROR,
  ITEM_PICK_WINNER_COMPLETED,
  MEMBERS_STATS_DOWNLOAD,
  MEMBERS_STATS_DOWNLOAD_COMPLETED,
  MEMBERS_STATS_DOWNLOAD_ERROR,
  RESET_APP_STATE,
  SOCIAL_LOGIN,
  TRANSFER,
  TRANSFER_COMPLETED,
  TRANSFER_ERROR,
  UPDATE_USER_QUEST_STATUS_COMPLETED,
  USERS_LIST_GET,
  USERS_LIST_GET_COMPLETED,
  USERS_LIST_GET_ERROR,
  USER_ALTERNATE_EMAIL_RESEND,
  USER_ALTERNATE_EMAIL_RESEND_COMPLETED,
  USER_ALTERNATE_EMAIL_RESEND_ERROR,
  USER_ALTERNATE_EMAIL_RESEND_RESET,
  USER_ALTERNATE_WALLET_LOGIN,
  USER_AWARD_POINTS,
  USER_AWARD_POINTS_COMPLETED,
  USER_AWARD_POINTS_ERROR,
  USER_DEAUTHORIZE,
  USER_DEAUTHORIZE_COMPLETED,
  USER_DEAUTHORIZE_ERROR,
  USER_DISCONNECT,
  USER_ENGAGEMENTS_CLAIM_COMPLETED,
  USER_ERROR_RESET,
  USER_FETCH_ME,
  USER_FETCH_ME_COMPLETED,
  USER_FETCH_ME_ERROR,
  USER_GET,
  USER_GET_COMPLETED,
  USER_GET_ERROR,
  USER_LEADERBOARD_LIST_GET,
  USER_LEADERBOARD_LIST_GET_COMPLETED,
  USER_LEADERBOARD_LIST_GET_ERROR,
  USER_LIST_RESET,
  USER_PROFILE_IMAGE_UPDATE,
  USER_PROFILE_IMAGE_UPDATE_COMPLETED,
  USER_PROFILE_IMAGE_UPDATE_ERROR,
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_COMPLETED,
  USER_PROFILE_UPDATE_ERROR,
  USER_REVOKE_POINTS,
  USER_REVOKE_POINTS_COMPLETED,
  USER_REVOKE_POINTS_ERROR,
  USER_SOCIAL_CONNECT,
  USER_SOCIAL_CONNECT__CANCELED,
  USER_SUGGESTION_OR_SUPPORT_SUBMISSION_RESET,
  USER_SUGGESTION_SUBMISSION,
  USER_SUGGESTION_SUBMISSION_COMPLETED,
  USER_SUGGESTION_SUBMISSION_ERROR,
  USER_SUGGESTION_SUBMISSION_ERROR_RESET,
  USER_TRANSFER_LORE,
  USER_TRANSFER_LORE_COMPLETED,
  USER_TRANSFER_LORE_ERROR,
  USER_TWITTER_PROFILE_CONFIRMATION_MODAL,
  USER_TWITTER_PROFILE_CONFIRMATION_MODAL_CANCEL,
  USER_UPDATE,
  USER_UPDATE_COMPLETED,
  USER_UPDATE_ERROR,
} from "../actions/actions.constants";

export interface UserState {
  isEmailResent?: boolean | null;
  email_identification_code?: string | null;
  emailToVerify?: string | null;
  nonce?: string | null;
  userData: User | null;
  currentTransactionHash: string;
  loading: boolean;
  loaded: boolean;
  error?: string;
  adminData: Admin | null;
  entities: Record<number, User>;
  entityIds: number[];
  hasMoreData: boolean;
  currentPage: number;
  currentId: number;
  metaTotalResult: number | null;
  prevPageFirstElementId: number;
  leaderboardEntities: {
    entities: Record<number, User>;
    entityIds: number[];
    loading: boolean;
    error: string;
  };
  twitter_profile_confirmation_modal?: boolean;
  social_loading?: boolean;
  is_alternate_login?: boolean;
  is_suggestion_sent?: boolean;
  is_suggestion_sending?: boolean;
  download_loading?: boolean;
  safety_check_link_error?: {
    in_suggestion?: boolean;
  };
}

const initialState: UserState = {
  entities: {},
  entityIds: [],
  userData: null,
  currentTransactionHash: "",
  loading: false,
  loaded: false,
  error: "",
  adminData: null,
  hasMoreData: false,
  currentPage: 1,
  currentId: -1,
  metaTotalResult: null,
  prevPageFirstElementId: -1,
  leaderboardEntities: {
    entities: {},
    entityIds: [],
    loading: false,
    error: "",
  },
  isEmailResent: null,
  emailToVerify: null,
  email_identification_code: null,
  nonce: null,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case HYDRATE: {
      if (action.payload.user) {
        // Only Hydrate these values
        const { userData, adminData, social_loading, loading } =
          action.payload.user;
        return {
          ...state,
          userData,
          adminData,
          social_loading,
          loading,
        };
      }
      break;
    }
    // case AUTH_GET_NONCE:
    case TRANSFER:
    case USER_SOCIAL_CONNECT: {
      return {
        ...state,
        social_loading: true,
        error: "",
      };
    }
    case ADMIN_IS_QUEST_APPROVAL_PENDING: {
      const is_quest_approval_pending =
        action.payload.res?.is_quest_approval_pending;
      return {
        ...state,
        adminData: {
          ...state.adminData,
          is_quest_approval_pending,
        },
      };
    }
    case ADD_WALLET_ADDRESS_VERIFY_SIGN: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_WALLET_ADDRESS_REJECT_SIGN: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_WALLET_ADDRESS_REJECT_SIGN_COMPLETED: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ADD_WALLET_ADDRESS_REJECT_SIGN_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case USER_SUGGESTION_SUBMISSION: {
      return {
        ...state,
        is_suggestion_sending: true,
        is_suggestion_sent: false,
        error: "",
      };
    }
    case USER_SUGGESTION_SUBMISSION_COMPLETED: {
      return {
        ...state,
        is_suggestion_sending: false,
        error: "",
        is_suggestion_sent: !!action.payload.res.success,
      };
    }
    case USER_SUGGESTION_SUBMISSION_ERROR: {
      const safety_check_link_error = action.payload?.safety_check_link_error;
      return {
        ...state,
        is_suggestion_sending: false,
        error: action.payload.message,
        safety_check_link_error,
        loading: false,
      };
    }
    case USER_SUGGESTION_OR_SUPPORT_SUBMISSION_RESET: {
      return {
        ...state,
        is_suggestion_sending: undefined,
        is_suggestion_sent: undefined,
      };
    }
    case USER_SUGGESTION_SUBMISSION_ERROR_RESET: {
      const { reset_suggestion_safety_link_error } = action.payload;
      return {
        ...state,
        safety_check_link_error: {
          in_suggestion: reset_suggestion_safety_link_error
            ? undefined
            : state.safety_check_link_error?.in_suggestion,
        },
        error: "",
      };
    }
    case ADD_WALLET_ADDRESS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ADD_WALLET_ADDRESS_COMPLETED: {
      return {
        ...state,
        nonce: action.payload.res.nonce,
        loading: false,
        loaded: true,
      };
    }

    // Add Alternate Email
    case ADD_EMAIL: {
      return {
        ...state,
        loading: true,
        loaded: false,
        emailToVerify: action.payload.req.email,
      };
    }
    case ADD_EMAIL_COMPLETED: {
      const alternate_email_data = action.payload.res.alternate_email_data;
      return {
        ...state,
        userData: { ...state.userData, alternate_email_data },
        email_identification_code: action.payload.res.code,
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case ADD_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        emailToVerify: null,
      };
    }

    // User Add Email Alternate Email Resend
    case USER_ALTERNATE_EMAIL_RESEND:
      return {
        ...state,
        isEmailResent: null,
      };

    case USER_ALTERNATE_EMAIL_RESEND_COMPLETED:
      return {
        ...state,
        isEmailResent: action.payload.res.isEmailResent,
      };

    case USER_ALTERNATE_EMAIL_RESEND_ERROR:
      return {
        ...state,
        isEmailResent: null,
        loading: false,
      };

    case USER_ALTERNATE_EMAIL_RESEND_RESET:
      return {
        ...state,
        isEmailResent: null,
      };

    // Add Alternate Login Completed (Called when Alt Check Verification or Alt Verify Signature Completes)
    case ADD_ALTERNATE_LOGIN_COMPLETED: {
      const user: any = action.payload.res.user;
      const admin: any = action.payload.res.admin;

      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user
        ? (user?.user_wallet_addresses as UserWalletAddressEntity[])
        : []
      ).forEach((address) => {
        userWalletAddressEntityIDs.add(address.id);
        userWalletAddressEntities = {
          ...userWalletAddressEntities,
          [address.id]: { ...address },
        };
      });

      return {
        ...state,
        userData: user
          ? {
              ...state.userData,
              ...user,
              user_wallet_addresses: {
                entities: { ...userWalletAddressEntities },
                entityIDs: Array.from(userWalletAddressEntityIDs),
              },
            }
          : user,
        adminData: admin ? { ...state.adminData, ...admin } : admin,
        loading: false,
        loaded: true,
        error: "",
        nonce: "",
        email_identification_code: null,
        emailToVerify: null,
        is_alternate_login: null,
      };
    }

    case USER_ALTERNATE_WALLET_LOGIN: {
      return {
        ...state,
        is_alternate_login: true,
      };
    }
    case ADD_WALLET_ADDRESS_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case ADD_WALLET_ADDRESS_VERIFY_SIGN_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    }
    case ADD_EMAIL_CHECK_VERIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        email_identification_code: null,
        emailToVerify: null,
      };
    }

    case ADD_ALTERNATE_LOGIN_CODE_RESET: {
      return {
        ...state,
        email_identification_code: undefined,
      };
    }
    case USER_ERROR_RESET: {
      return { ...state, error: "" };
    }
    case USER_SOCIAL_CONNECT__CANCELED: {
      return {
        ...state,
        social_loading: false,
        error: "",
      };
    }
    case USERS_LIST_GET:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USERS_LIST_GET_COMPLETED:
      const users: any[] = action.payload.res.users;
      const sanitizedUsers = users.reduce((cumulation: any, user: any) => {
        return {
          ...cumulation,
          [user.id]: {
            ...user,
            user_wallet_addresses: {
              entities: (
                user?.user_wallet_addresses as UserWalletAddressEntity[]
              ).reduce((commutator: any, address) => {
                return {
                  ...commutator,
                  [address.id]: { ...address },
                };
              }, {}),
              entityIDs: (
                user?.user_wallet_addresses as UserWalletAddressEntity[]
              ).reduce((commutator: any, address) => {
                return [...commutator, address.id];
              }, []),
            },
          },
        };
      }, {});

      const hasMoreData =
        action.payload.res.meta.current_page <
        action.payload.res.meta.last_page;
      const currentPage = action.payload.res.meta.current_page;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.payload.res.users.length
        ? action.payload.res.users[0].id
        : state.prevPageFirstElementId;
      const metaTotalResult = action.payload.res.meta?.total;
      const ids = users.reduce((cumulation: any, user: User) => {
        return [...cumulation, user.id];
      }, []);

      return {
        ...state,
        entities: { ...state.entities, ...sanitizedUsers },
        entityIds: [...state.entityIds, ...ids],
        loading: false,
        loaded: true,
        hasMoreData,
        currentPage,
        metaTotalResult,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
      };
    case USERS_LIST_GET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case USER_ENGAGEMENTS_CLAIM_COMPLETED: {
      const amount = action.payload.res?.amount;
      return {
        ...state,
        userData: {
          ...state.userData,
          points: (state.userData?.points as number) + amount,
          lifetime_points: (state.userData?.lifetime_points as number) + amount,
        },
      };
    }
    case USER_UPDATE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    }
    case USER_UPDATE_COMPLETED: {
      const updateUser: any = action.payload.res.user;
      const socialLogins: Social[] = action.payload.res.user.user_socials;

      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (updateUser?.user_wallet_addresses as UserWalletAddressEntity[]).forEach(
        (address) => {
          userWalletAddressEntityIDs.add(address.id);
          userWalletAddressEntities = {
            ...userWalletAddressEntities,
            [address.id]: { ...address },
          };
        }
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          ...updateUser,
          user_wallet_addresses: {
            entities: { ...userWalletAddressEntities },
            entityIDs: Array.from(userWalletAddressEntityIDs),
          },

          twitter_username:
            socialLogins.find((social) => social.provider === Providers.TWITTER)
              ?.username || null,
          instagram_username:
            socialLogins.find(
              (social) => social.provider === Providers.INSTAGRAM
            )?.username || null,
          discord_username:
            socialLogins.find((social) => social.provider === Providers.DISCORD)
              ?.username || null,
          youtube_username:
            socialLogins.find((social) => social.provider === Providers.YOUTUBE)
              ?.username || null,
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case USER_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case USER_REVOKE_POINTS:
    case USER_AWARD_POINTS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_AWARD_POINTS_COMPLETED: {
      const updateUser: User = action.payload.res.user;
      return {
        ...state,
        entities: {
          ...state.entities,
          [updateUser.id]: {
            ...state.entities[updateUser.id],
            sum:
              +(state.entities[updateUser.id].sum || 0) +
              +action.payload.gifted,
            ...updateUser,
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case USER_REVOKE_POINTS_COMPLETED: {
      const updateUser: User = action.payload.res.user;
      return {
        ...state,
        entities: {
          ...state.entities,
          [updateUser.id]: {
            ...state.entities[updateUser.id],
            sum: +(state.entities[updateUser.id].sum || 0),
            ...updateUser,
          },
        },
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case USER_REVOKE_POINTS_ERROR:
    case USER_AWARD_POINTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case USER_LEADERBOARD_LIST_GET:
      return {
        ...state,
        leaderboardEntities: {
          ...state.leaderboardEntities,
          loading: true,
          error: "",
        },
      };
    case USER_LEADERBOARD_LIST_GET_COMPLETED:
      const myRank = action.payload.res.rank;
      const userList: any[] = action.payload.res.users;
      const sanitizedUsersList = userList.reduce((cumulation: any, value) => {
        return {
          ...cumulation,
          [value.id]: {
            ...value,
            user_wallet_addresses: {
              entities: (
                value?.user_wallet_addresses as UserWalletAddressEntity[]
              ).reduce((commutator: any, address) => {
                return {
                  ...commutator,
                  [address.id]: { ...address },
                };
              }, {}),
              entityIDs: (
                value?.user_wallet_addresses as UserWalletAddressEntity[]
              ).reduce((commutator: any, address) => {
                return [...commutator, address.id];
              }, []),
            },
          },
        };
      }, {});
      const userIds = userList.reduce((cumulation: any, value: User) => {
        return [...cumulation, value.id];
      }, []);

      return {
        ...state,
        userData: { ...state.userData, rank: myRank },
        leaderboardEntities: {
          ...state.leaderboardEntities,
          entities: { ...sanitizedUsersList },
          entityIds: [...userIds],
          loading: false,
          error: "",
        },
      };
    case USER_LEADERBOARD_LIST_GET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        leaderboardEntities: {
          ...state.leaderboardEntities,
          loading: false,
          error: action.payload?.message,
        },
      };
    case USER_GET:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_GET_COMPLETED:
      const entityIds = new Set(state.entityIds);
      entityIds.add(action.payload.res.user.id);

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.res.user.id]: { ...action.payload.res.user },
        },
        entityIds: Array.from(entityIds),
        loading: false,
        loaded: true,
        error: "",
      };
    case USER_GET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case USER_LIST_RESET:
      return {
        ...state,
        entities: {},
        entityIds: [],
        error: "",
        loading: false,
        loaded: true,
        hasMoreData: false,
        currentPage: 1,
      };
    case USER_FETCH_ME:
      return {
        ...state,
        loading: true,
      };
    case USER_FETCH_ME_COMPLETED:
      const user: any = action.data.data.user;
      const admin: Admin = action.data.data.admin;
      const socialLogins: Social[] = !!user
        ? action.data.data.user.user_socials
        : [];

      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user
        ? (user?.user_wallet_addresses as UserWalletAddressEntity[])
        : []
      ).forEach((address) => {
        userWalletAddressEntityIDs.add(address.id);
        userWalletAddressEntities = {
          ...userWalletAddressEntities,
          [address.id]: { ...address },
        };
      });
      return {
        ...state,
        userData: user
          ? {
              ...state.userData,
              ...user,
              user_wallet_addresses: {
                entities: { ...userWalletAddressEntities },
                entityIDs: Array.from(userWalletAddressEntityIDs),
              },
              twitter_username:
                socialLogins.find(
                  (social) => social.provider === Providers.TWITTER
                )?.username || null,
              instagram_username:
                socialLogins.find(
                  (social) => social.provider === Providers.INSTAGRAM
                )?.username || null,
              discord_username:
                socialLogins.find(
                  (social) => social.provider === Providers.DISCORD
                )?.username || null,
              youtube_username:
                socialLogins.find(
                  (social) => social.provider === Providers.YOUTUBE
                )?.username || null,
            }
          : null,
        adminData: admin ? { ...state.adminData, ...admin } : admin,
        social_loading: false,
        loading: false,
      };
    case USER_FETCH_ME_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    }
    case AUTH_VERIFY_SIGNATURE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case AUTH_LOGIN_COMPLETED: {
      const user: any = action.data.data.user;
      const admin: any = action.data.data.admin;
      const socialLogins: Social[] = !!user
        ? action.data.data.user.user_socials
        : [];
      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user
        ? (user?.user_wallet_addresses as UserWalletAddressEntity[])
        : []
      ).forEach((address) => {
        userWalletAddressEntityIDs.add(address.id);
        userWalletAddressEntities = {
          ...userWalletAddressEntities,
          [address.id]: { ...address },
        };
      });
      return {
        ...state,
        userData: user
          ? {
              ...state.userData,
              ...user,
              user_wallet_addresses: {
                entities: { ...userWalletAddressEntities },
                entityIDs: Array.from(userWalletAddressEntityIDs),
              },
              twitter_username:
                socialLogins.find(
                  (social) => social.provider === Providers.TWITTER
                )?.username || null,
              instagram_username:
                socialLogins.find(
                  (social) => social.provider === Providers.INSTAGRAM
                )?.username || null,
              discord_username:
                socialLogins.find(
                  (social) => social.provider === Providers.DISCORD
                )?.username || null,
              youtube_username:
                socialLogins.find(
                  (social) => social.provider === Providers.YOUTUBE
                )?.username || null,
            }
          : null,
        adminData: admin
          ? {
              ...state.adminData,
              ...admin,
            }
          : null,
        loading: false,
      };
    }
    case USER_TRANSFER_LORE: {
      return {
        ...state,
        loading: true,
      };
    }
    case TRANSFER_COMPLETED:
    case USER_TRANSFER_LORE_COMPLETED: {
      return {
        ...state,
        loading: false,
      };
    }
    case TRANSFER_ERROR:
    case USER_TRANSFER_LORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    }
    case SOCIAL_LOGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_DISCONNECT: {
      return initialState;
    }
    case USER_ENGAGEMENTS_CLAIM_COMPLETED:
      const amount: number = +action.payload.res.amount;

      return {
        ...state,
        userData: {
          ...state.userData,
          points: (state.userData?.points as number) + amount,
        },
      };
    case USER_PROFILE_IMAGE_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_PROFILE_IMAGE_UPDATE_COMPLETED: {
      const user: any = action.payload.res.user;
      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user?.user_wallet_addresses as UserWalletAddressEntity[]).forEach(
        (address) => {
          userWalletAddressEntityIDs.add(address.id);
          userWalletAddressEntities = {
            ...userWalletAddressEntities,
            [address.id]: { ...address },
          };
        }
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          ...user,
          user_wallet_addresses: {
            entities: { ...userWalletAddressEntities },
            entityIDs: Array.from(userWalletAddressEntityIDs),
          },
        },
        loading: false,
        loaded: true,
      };
    }
    case USER_PROFILE_IMAGE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case USER_PROFILE_UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_PROFILE_UPDATE_COMPLETED: {
      const user: any = action.payload.res.user;
      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user?.user_wallet_addresses as UserWalletAddressEntity[]).forEach(
        (address) => {
          userWalletAddressEntityIDs.add(address.id);
          userWalletAddressEntities = {
            ...userWalletAddressEntities,
            [address.id]: { ...address },
          };
        }
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          ...user,
          user_wallet_addresses: {
            entities: { ...userWalletAddressEntities },
            entityIDs: Array.from(userWalletAddressEntityIDs),
          },
        },
        loading: false,
        loaded: true,
      };
    }
    case USER_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case USER_DEAUTHORIZE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: "",
      };
    case USER_DEAUTHORIZE_COMPLETED: {
      const user: any = action.payload.res.user;
      const userWalletAddressEntityIDs = new Set(
        state.userData?.user_wallet_addresses.entityIDs
      );
      let userWalletAddressEntities: any;

      (user?.user_wallet_addresses as UserWalletAddressEntity[]).forEach(
        (address) => {
          userWalletAddressEntityIDs.add(address.id);
          userWalletAddressEntities = {
            ...userWalletAddressEntities,
            [address.id]: { ...address },
          };
        }
      );
      return {
        ...state,
        userData: {
          ...user,
          user_wallet_addresses: {
            entities: { ...userWalletAddressEntities },
            entityIDs: Array.from(userWalletAddressEntityIDs),
          },
          twitter_username:
            user.user_socials.find(
              (social: Social) => social.provider === Providers.TWITTER
            )?.username || null,
          instagram_username:
            user.user_socials.find(
              (social: Social) => social.provider === Providers.INSTAGRAM
            )?.username || null,
          discord_username:
            user.user_socials.find(
              (social: Social) => social.provider === Providers.DISCORD
            )?.username || null,
          youtube_username:
            user.user_socials.find(
              (social: Social) => social.provider === Providers.YOUTUBE
            )?.username || null,
        },
        loading: false,
        loaded: true,
      };
    }
    case USER_DEAUTHORIZE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.message,
      };
    case USER_TWITTER_PROFILE_CONFIRMATION_MODAL: {
      return {
        ...state,
        twitter_profile_confirmation_modal: true,
        social_loading: false,
        loading: false,
        loaded: true,
      };
    }
    case UPDATE_USER_QUEST_STATUS_COMPLETED: {
      const updatedQuest: UserQuest = action.payload.res.updatedQuest;
      const user = state.entities[updatedQuest.user_id];
      return {
        ...state,
        entities: {
          ...state.entities,
          [updatedQuest.user_id]: {
            ...user,
            points: user?.points + (updatedQuest?.amount ?? 0),
          },
        },
      };
    }
    case GET_USER_REFERRAL_CODE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_REFERRAL_CODE_COMPLETED: {
      const referral_code = action.payload.res.referral_code;
      const userData = { ...state.userData, referral_code };
      return {
        ...state,
        userData: userData,
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case GET_USER_REFERRAL_CODE_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.message,
      };
    }
    case USER_TWITTER_PROFILE_CONFIRMATION_MODAL_CANCEL: {
      return {
        ...state,
        twitter_profile_confirmation_modal: false,
      };
    }

    case MEMBERS_STATS_DOWNLOAD: {
      return {
        ...state,
        download_loading: true,
      };
    }
    case MEMBERS_STATS_DOWNLOAD_COMPLETED: {
      return {
        ...state,
        download_loading: false,
      };
    }
    case MEMBERS_STATS_DOWNLOAD_ERROR: {
      return {
        ...state,
        download_loading: false,
        error: action.payload.message,
      };
    }

    default: {
      return state;
    }
  }
};
